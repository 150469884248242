.App {
  text-align: center;
  padding: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
}

th {
  background-color: #f2f2f2;
}

td.amount {
  text-align: right; /* Right-align for numeric fields */
}

input[type="file"] {
  margin-top: 20px;
}

h1 {
  font-size: 1.5rem;
}

.btn-link {
  padding: 0;
  text-decoration: none;
  font-weight: bold;
}


@media (max-width: 600px) {
  th, td {
    font-size: 0.8rem;
    padding: 6px;
  }

  h1 {
    font-size: 1.2rem;
  }
}

